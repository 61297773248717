import React, { useRef, useState } from "react"
import cls from "./business.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"
import useClickOutside from "@/shared/lib/hooks/useClickOutside"

export const BusinessInfo = () => {
    const [open, setOpen] = useState(false)
    const infoRef = useRef()

    useClickOutside(infoRef, () => {
        setOpen(false)
    })

    return (
        <div
            ref={infoRef}
            className={cls.container}>
            <div
                className={classNames(cls.menu, [], {
                    [cls.close]: !open,
                })}>
                <h3>Business Information</h3>
                <table>
                    <tbody className={cls.tableBody}>
                        <tr>
                            <th scope='row'>ID</th>
                            <td>53870093</td>
                        </tr>
                        <tr>
                            <th scope='row'>TIN</th>
                            <td>2121530565</td>
                        </tr>
                        <tr>
                            <th scope='row'>VAT NUMBER</th>
                            <td
                                style={{
                                    textAlign: "right",
                                }}>
                                SK2121530565, according to
                                §7a, registration from
                                11.1.2022
                            </td>
                        </tr>
                        <tr className={cls.large}>
                            <th scope='col'>Head office</th>
                            <td>
                                Softner Solutions, s.r.o.
                                Lermontovova 3 811 05
                                Bratislava - Old Town
                            </td>
                        </tr>
                        <tr className={cls.large}>
                            <th scope='col'>
                                Date of creation
                            </th>
                            <td>Saturday, 26 June 2021</td>
                        </tr>
                        <tr className={cls.large}>
                            <th scope='col'>SK NACE</th>
                            <td>
                                according to the financial
                                statements: 62010 Computer
                                Programming according to the
                                Statistical Office of the
                                Slovak Republic: 62090 Other
                                information services
                                information technology and
                                computers
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button
                onClick={() => setOpen(!open)}
                className={classNames(
                    cls.BusinessInfo,
                    [],
                    {
                        [cls.open]: open,
                    }
                )}>
                Business Info
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'>
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M10 7C10.3148 7 10.6112 7.14819 10.8 7.4L13.8 11.4C14.0273 11.703 14.0638 12.1084 13.8944 12.4472C13.725 12.786 13.3788 13 13 13H7.00001C6.62123 13 6.27497 12.786 6.10558 12.4472C5.93619 12.1084 5.97274 11.703 6.20001 11.4L9.20001 7.4C9.38886 7.14819 9.68525 7 10 7Z'
                        fill='black'
                    />
                </svg>
            </button>
        </div>
    )
}
