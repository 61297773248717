import React from "react"
import cls from "./feedbackcard.module.scss"
export const FeedbackCard = ({
    src,
    title,
    description,
}) => {
    return (
        <div className={cls.FeedbackCard}>
            <img src={src} />
            <h3>{title}</h3>
            <div className={cls.breakLine} />
            <p>{description}</p>
        </div>
    )
}
