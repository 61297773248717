import React from "react"
import styles from "./footer.module.scss"

import { ReactComponent as MiniLogo } from "@/shared/assets/icons/miniLogo.svg"
import { BusinessInfo } from "../BusinessInfo/BusinessInfo"


export const Footer = ({ variant }) => {
    return (
        <footer
            className={
                variant === "dark"
                    ? styles.footer_dark
                    : styles.footer
            }>
            <p>© 2023 Axgrid. All rights reserved</p>
            <MiniLogo
                className={styles.MiniLogo}
                onClick={() => window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                })}
            />
            <BusinessInfo />
        </footer>
    )
}
