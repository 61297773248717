import React from "react"
import Dmitrii from "@/shared/assets/Dmitrii.webp"
import Artem from "@/shared/assets/Artem.webp"
import Samsonov from "@/shared/assets/samsonov.webp"
import Ruslan from "@/shared/assets/Ruslan.webp"
import waveTeam from "@/shared/assets/waveTeam.webp"

import cls from "./team.module.scss"
import { TeamCard } from "@/entities/TeamCard/TeamCard"

export const TeamBlock = ({ id }) => {
    const data = [
        {
            name: "Dmitrii\n Vysochin",
            role: "CEO",
            img: Dmitrii,
        },
        {
            name: "Artem\n Gorodetskii",
            role: "CPO",
            img: Artem,
        },

        {
            name: "Dmitrii\n Samsonov",
            role: "CTO",
            img: Samsonov,
        },
        {
            name: "Lev\n Oparin",
            role: "Director",
            img: Ruslan,
        },
    ]
    return (
        <div
            id={id}
            className={cls.TeamBlock}>
            {/* <svg
                className={cls.waveUp}
                xmlns='http://www.w3.org/2000/svg'
                width='1440'
                height='1475'
                viewBox='0 0 1440 1475'
                fill='none'>
                <g filter='url(#filter0_f_270_1002)'>
                    <path
                        d='M576.863 707.393C119.399 645.443 -159.326 808.175 -241.506 897.285C-645.505 1071.82 -1332.44 1671.1 -526.14 1261.69C501.456 739.904 970.956 1261.69 1742.37 1049.88C2575.82 821.03 2619.94 -298.563 1958.6 197.574C1297.26 693.712 1148.69 784.832 576.863 707.393Z'
                        fill='white'
                    />
                </g>
                <defs>
                    <filter
                        id='filter0_f_270_1002'
                        x='-972.534'
                        y='0.0792236'
                        width='3459.82'
                        height='1473.97'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                        <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                        />
                        <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='BackgroundImageFix'
                            result='shape'
                        />
                        <feGaussianBlur
                            stdDeviation='36'
                            result='effect1_foregroundBlur_270_1002'
                        />
                    </filter>
                </defs>
            </svg> */}
            <img
                src={waveTeam}
                className={cls.waveUp}
                alt="wave"
            />
            <h4>Team</h4>
            <div className={cls.TeamContainer}>
                {data.map((item, index) => {
                    return (
                        <TeamCard
                            key={index}
                            name={item.name}
                            role={item.role}>
                            <img
                                src={item.img}
                                className={cls.teammateImg}
                            />
                        </TeamCard>
                    )
                })}
            </div>
        </div>
    )
}
