import React from "react"
import ReactDOM from "react-dom/client"
import "@/app/index.scss"
import App from "@/app/App"
import {
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom"
import "./i18n.js"
import { Main } from "@/page/Main/Main.jsx"
import { PrivacyPolicy } from "./page/PrivacyPolicy/PrivacyPolicy.jsx"
import { LicenseCheck } from "./page/LicenseCheck/LicenseCheck.jsx"

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <Main />,
            },
            {
                path: "/privacy",
                element: <PrivacyPolicy />,
            },
            {
                path: "/license_check",
                element: <LicenseCheck />,
            },
        ],
    },
])

const root = ReactDOM.createRoot(
    document.getElementById("root")
)
root.render(<RouterProvider router={router} />)
