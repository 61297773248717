import React, { useEffect } from "react"
import cls from "./privacy.module.scss"

const data = [
    {
        title: "",
        content: [
            {
                description:
                    "Our Company is part of the Our Company Group which includes Our Company International and Our Company Direct. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.",
                points: [],
            },
        ],
    },
    {
        title: "1. What data do we collect?",
        content: [
            {
                description:
                    "Our Company collects the following data:",
                points: [
                    "Personal identification information (Name, email address, phone number)",
                ],
            },
        ],
    },
    {
        title: "2. How do we collect your data?",
        content: [
            {
                description:
                    "You directly provide Our Company with most of the data we collect. We collect data and process data when you:",
                points: [
                    "Register online or place an order for any of our products or services.",
                    "Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.",
                    "Use or view our website via your browser’s cookies.",
                ],
            },
        ],
    },
    {
        title: "3. How will we use your data?",
        content: [
            {
                description:
                    "Our Company collects your data so that we can:",
                points: [
                    "Process your order and manage your account.",
                    "Email you with special offers on other products and services we think you might like.",
                ],
            },

            {
                description:
                    "When Our Company processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.",
                points: [],
            },
        ],
    },
    {
        title: "4. Marketing",
        content: [
            {
                description:
                    "Our Company would like to send you information about products and services of ours that we think you might like, as well as those of our partner companies.",
                points: [],
            },

            {
                description:
                    "If you have agreed to receive marketing, you may always opt out at a later date.",
                points: [],
            },
            {
                description:
                    "You have the right at any time to stop Our Company from contacting you for marketing purposes or giving your data to other members of the Our Company Group.",
                points: [],
            },
            {
                description:
                    "If you no longer wish to be contacted for marketing purposes, please click here.",
                points: [],
            },
        ],
    },
    {
        title: "5. What are your data protection rights?",
        content: [
            {
                description:
                    "Our Company would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:",
                points: [],
            },
            {
                description:
                    "The right to access – You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.",
                points: [],
            },

            {
                description:
                    "The right to rectification – You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.",
                points: [],
            },
            {
                description:
                    "The right to restrict processing – You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.",
                points: [],
            },
            {
                description:
                    "The right to object to processing – You have the right to object to Our Company’s processing of your personal data, under certain conditions.",
                points: [],
            },
            {
                description:
                    "The right to data portability – You have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
                points: [],
            },
        ],
    },
]

export const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <div className={cls.container}>
                <h1>Privacy Policy</h1>

                <p>
                    Our Company is part of the Our Company
                    Group which includes Our Company
                    International and Our Company Direct.
                    This privacy policy will explain how our
                    organization uses the personal data we
                    collect from you when you use our
                    website.
                </p>

                <h2>1. What data do we collect?</h2>
                <div className={cls.points}>
                    <p>
                        Our Company collects the following
                        data:
                    </p>
                    <ul>
                        <li>
                            Personal identification
                            information (Name, email
                            address, phone number)
                        </li>
                    </ul>
                </div>

                <h2>2. How do we collect your data?</h2>
                <div className={cls.points}>
                    <p>
                        You directly provide Our Company
                        with most of the data we collect. We
                        collect data and process data when
                        you:
                    </p>
                    <ul>
                        <li>
                            Register online or place an
                            order for any of our products or
                            services.
                        </li>
                        <li>
                            Voluntarily complete a customer
                            survey or provide feedback on
                            any of our message boards or via
                            email.
                        </li>
                        <li>
                            Use or view our website via your
                            browser’s cookies.
                        </li>
                    </ul>
                </div>
                <h2>3. How will we use your data?</h2>
                <div className={cls.points}>
                    <p>
                        Our Company collects your data so
                        that we can:
                    </p>
                    <ul>
                        <li>
                            Process your order and manage
                            your account.
                        </li>
                        <li>
                            Email you with special offers on
                            other products and services we
                            think you might like.
                        </li>
                    </ul>
                    <p>
                        When Our Company processes your
                        order, it may send your data to, and
                        also use the resulting information
                        from, credit reference agencies to
                        prevent fraudulent purchases.
                    </p>
                </div>

                <h2>4. Marketing</h2>
                <div className={cls.points}>
                    <p>
                        Our Company would like to send you
                        information about products and
                        services of ours that we think you
                        might like, as well as those of our
                        partner companies.
                    </p>
                    <p>
                        If you have agreed to receive
                        marketing, you may always opt out at
                        a later date.
                    </p>
                    <p>
                        You have the right at any time to
                        stop Our Company from contacting you
                        for marketing purposes or giving
                        your data to other members of the
                        Our Company Group.
                    </p>
                </div>

                <h2>
                    5. What are your data protection rights?
                </h2>
                <div className={cls.points}>
                    <p>
                        Our Company would like to make sure
                        you are fully aware of all of your
                        data protection rights. Every user
                        is entitled to the following:
                    </p>
                    <ul>
                        <li>
                            <strong>
                                The right to access
                            </strong>{" "}
                            – You have the right to request
                            Our Company for copies of your
                            personal data. We may charge you
                            a small fee for this service.
                        </li>
                        <li>
                            <strong>
                                The right to rectification
                            </strong>{" "}
                            – You have the right to request
                            that Our Company correct any
                            information you believe is
                            inaccurate. You also have the
                            right to request Our Company to
                            complete the information you
                            believe is incomplete.
                        </li>
                        <li>
                            <strong>
                                The right to restrict
                                processing
                            </strong>{" "}
                            – You have the right to request
                            that Our Company restrict the
                            processing of your personal
                            data, under certain conditions.
                        </li>
                        <li>
                            <strong>
                                The right to object to
                                processing
                            </strong>{" "}
                            – You have the right to object
                            to Our Company’s processing of
                            your personal data, under
                            certain conditions.
                        </li>
                        <li>
                            <strong>
                                The right to data
                                portability
                            </strong>{" "}
                            – You have the right to request
                            that Our Company transfer the
                            data that we have collected to
                            another organization, or
                            directly to you, under certain
                            conditions.
                        </li>
                    </ul>
                </div>
                <h1>Cookie Policy</h1>

                <section>
                    <p>
                        Cookies are text files placed on
                        your computer to collect standard
                        Internet log information and visitor
                        behavior information. When you visit
                        our websites, we may collect
                        information from you automatically
                        through cookies or similar
                        technology.
                    </p>
                </section>

                <section>
                    <h2>1. How do we use cookies?</h2>
                    <p>
                        Our Company collects the following
                        data:
                    </p>
                    <ul>
                        <li>Keeping you signed in</li>
                        <li>
                            Understanding how you use our
                            website
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>
                        2. What types of cookies do we use?
                    </h2>
                    <p>
                        There are a number of different
                        types of cookies, however, our
                        website uses:
                    </p>
                    <ul>
                        <li>
                            <strong>Functionality</strong> –
                            Our Company uses these cookies
                            so that we recognize you on our
                            website and remember your
                            previously selected preferences.
                            These could include what
                            language you prefer and location
                            you are in. A mix of first-party
                            and third-party cookies are
                            used.
                        </li>
                        <li>
                            <strong>Advertising</strong> –
                            Our Company uses these cookies
                            to collect information about
                            your visit to our website, the
                            content you viewed, the links
                            you followed, and information
                            about your browser, device, and
                            your IP address. Our Company
                            sometimes shares some limited
                            aspects of this data with third
                            parties for advertising
                            purposes. We may also share
                            online data collected through
                            cookies with our advertising
                            partners. This means that when
                            you visit another website, you
                            may be shown advertising based
                            on your browsing patterns on our
                            website.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>3. How to manage cookies</h2>
                    <p>
                        You can set your browser not to
                        accept cookies, and the above
                        website tells you how to remove
                        cookies from your browser. However,
                        in a few cases, some of our website
                        features may not function as a
                        result.
                    </p>
                </section>
            </div>
        </div>
    )
}
