import React from "react"
import { ReactComponent as DiagArrow } from "@/shared/assets/icons/diagArrow.svg"
import { classNames } from "@/shared/lib/utils/classNames"

import cls from "./button.module.scss"

export const Button = ({
    children,
    onClick,
    className,
    disabled = false,
    variant = "none",
    withArrow = false,
}) => {
    const variants = {
        primary: "primary",
        secondary: "secondary",
        outlined: "outlined",
        white: "white",
        black: "black",
        none: "none",
    }
    return (
        <button
            onClick={onClick}
            className={classNames(
                cls.Button,
                [className, cls[variants[variant]]],
                {
                    [cls.withArrow]: withArrow,
                    [cls.disabled]: disabled,
                }
            )}>
            <p>{children}</p>
            {withArrow && (
                <DiagArrow className={cls.DiagArrow} />
            )}
        </button>
    )
}
