import React from "react"
// import { Advantages } from "@/shared/ui/Advantages/Advantages"
import backgroundimage from "@/shared/assets/bg.png"
import cls from "./main.module.scss"
import { HomeBlock } from "@/widgets/HomeBlock/HomeBlock"
import { Services } from "@/widgets/Services/Services"
import { ProductsBlock } from "@/widgets/ProductsBlock/ProductsBlock"
import { TeamBlock } from "@/widgets/TeamBlock/TeamBlock"
import { Feedback } from "@/widgets/Feedback/Feedback"
import { Contacts } from "@/widgets/Contacts/Contacts"
import { Footer } from "@/widgets/footer/Footer"
import { classNames } from "@/shared/lib/utils/classNames"
import { useLocation } from "react-router-dom"

export const Main = () => {
    const location = useLocation()
    return (
        <div className={cls.wrapper}>
            <img
                className={cls.background}
                style={{ background: "#7617DB" }}
                src={backgroundimage}
                alt='bg'
            />
            <div
                id='mainComponent'
                className={cls.Main}>
                <HomeBlock id={"home"} />
                <Services id={"services"} />
                <ProductsBlock id={"products"} />
                <TeamBlock id={"about"} />
                <Feedback />
                <Contacts id={"contactUs"} />
            </div>

            <div
                style={{
                    display:
                        location.pathname !== "/"
                            ? "none"
                            : "flex",
                }}
                className={cls.orbitContainer}>
                <div className={cls.orbit} />
                <div
                    className={classNames(cls.orbit, [
                        cls.second,
                    ])}
                />

                <div className={cls.animCircle}>
                    <div className={cls.animCircleDot} />
                    <div
                        className={classNames(
                            cls.animCircleDot,
                            [cls.second]
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
