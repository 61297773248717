import React from "react"
import cls from "./teamcard.module.scss"
export const TeamCard = ({ name, role, children }) => {
    return (
        <div className={cls.container}>
            <div className={cls.img}>{children}</div>
            <div className={cls.typography}>
                <h1 className={cls.name}>{name}</h1>
                <p className={cls.role}>
                    {role.toUpperCase()}
                </p>
            </div>
        </div>
    )
}
