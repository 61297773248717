import React from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import cls from "./text_area.module.scss"

export const BaseTextArea = ({
    placeholder,
    className,
    value,
    onChange,
    isValid,
}) => {
    return (
        <div
            className={classNames(cls.container, [
                className,
            ])}>
            {value !== "" && <p className={cls.placeholder}>{placeholder + " *"}</p>}
            <textarea
                className={classNames(
                    cls.baseTextarea,
                    [className],
                    {
                        [cls.invalid]: !isValid,
                    }
                )}
                value={value}
                onChange={onChange}
                cols={5}
                placeholder={placeholder + " *"}
            />
            <div
                className={classNames(cls.warning, [], {
                    [cls.hide]: isValid,
                    [cls.noText]: value.trim() == ''
                })}>
                Fill this field *
            </div>
        </div>
    )
}
