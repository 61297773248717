import React from "react"
import bgLine from "@/shared/assets/bgLine.svg"
import cls from "./home.module.scss"

export const HomeBlock = ({ id }) => {
    return (
        <>
            <div
                id={id}
                className={cls.homeContainer}>
                <div className={cls.HomeBlock}>
                    <div className={cls.text}>
                        <div>
                            <h1>
                                Development{" "}
                                <span
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}>
                                    of
                                </span>
                            </h1>
                            <h1>Innovative Tech </h1>
                            <h1>Solutions</h1>
                        </div>
                        <p>
                            We are at the intersection of
                            cutting-edge technology and peak
                            performance, we craft unique
                            products designed to meet any
                            need. Our tech solutions are
                            engineered with precision,
                            delivering unparalleled quality
                            and innovation in every aspect.
                        </p>
                    </div>
                    <svg
                        className={cls.iconStar}
                        xmlns='http://www.w3.org/2000/svg'
                        width='56'
                        height='56'
                        viewBox='0 0 56 56'
                        fill='none'>
                        <path
                            d='M29.7532 -3.46877e-07L29.0439 25.3654L46.4626 6.92185L49.064 9.51673L30.5815 26.8986L56 26.1909L56 29.8091L30.5815 29.1009L49.064 46.4833L46.4626 49.0786L29.0444 30.6351L29.7532 56L26.1278 56L26.9556 30.6351L9.49702 49.0787L6.93549 46.4833L25.4185 29.1009L-1.14484e-06 29.8091L-1.303e-06 26.1909L25.4185 26.8986L6.936 9.51723L9.49702 6.92135L26.9556 25.3654L26.2458 0.000507161L29.7532 -3.46877e-07Z'
                            fill='white'
                        />
                    </svg>
                </div>
                <img
                    className={cls.bgLine}
                    src={bgLine}
                    alt='bgLine'
                />
            </div>
        </>
    )
}
