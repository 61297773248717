import React, { useState } from "react"
import miniPC from "@/shared/assets/miniPCX2.webp"
import { ReactComponent as XMark } from "@/shared/assets/icons/xMark.svg"
import { ReactComponent as File } from "@/shared/assets/icons/file.svg"
import cls from "./configuration.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"
export const Configuration = ({ onClick }) => {
    const [selectedTab, setTab] = useState("config")
    return (
        <div className={cls.Configuration}>
            <XMark
                onClick={onClick}
                className={cls.xMark}
            />
            <img
                src={miniPC}
                alt='miniPc'
            />
            <div className={cls.rightContainer}>
                <div className={cls.tabs}>
                    <h3
                        onClick={() => setTab("config")}
                        className={classNames(cls.tab, [], {
                            [cls.selected]:
                                selectedTab === "config",
                        })}>
                        Configuration
                    </h3>
                    {/* <h3
                        onClick={() => setTab("docs")}
                        className={classNames(cls.tab, [], {
                            [cls.selected]:
                                selectedTab === "docs",
                        })}>
                        Documentation
                    </h3> */}
                </div>
                {selectedTab === "config" && (
                    <div className={cls.specification}>
                        <table>
                            <tr>
                                <td>Color</td>
                                <td>Black</td>
                            </tr>
                            <tr>
                                <td>Supply voltage</td>
                                <td>100V-240V</td>
                            </tr>
                            <tr>
                                <td>Host voltage</td>
                                <td>12V 5A</td>
                            </tr>
                            <tr>
                                <td>CPU</td>
                                <td>
                                    Intel N200, Quad core, 4
                                    Threads, up to 3.7 GHz
                                    Frequency
                                </td>
                            </tr>
                            <tr>
                                <td>Graphics</td>
                                <td>
                                    Intel® UHD Graphics
                                    integrated graphics
                                </td>
                            </tr>
                            <tr>
                                <td>Memory</td>
                                <td>DDR4 8G</td>
                            </tr>
                            <tr>
                                <td>Hard disk</td>
                                <td>512G SSD</td>
                            </tr>
                            <tr>
                                <td>Wifi</td>
                                <td>Support M.2 wifi</td>
                            </tr>
                            <tr>
                                <td>Work temperature</td>
                                <td>
                                    0°C to +50°C surface air
                                    flow
                                </td>
                            </tr>
                            <tr>
                                <td>Work humidity</td>
                                <td>
                                    5% to 95% non condensing
                                    state
                                </td>
                            </tr>
                            <tr>
                                <td>Storage Environment</td>
                                <td>
                                    -20°C to 80°C non
                                    condensing state
                                </td>
                            </tr>
                            <tr>
                                <td>Installation</td>
                                <td>
                                    Desktop or VESA mount
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Safety certification
                                </td>
                                <td>
                                    CE, CCC, FCC Class A,
                                    ROHS
                                </td>
                            </tr>
                            <tr>
                                <td>Net weight</td>
                                <td>2.8 KG</td>
                            </tr>
                            <tr>
                                <td>Gross weight</td>
                                <td>2.5 KG</td>
                            </tr>
                            <tr>
                                <td>Packaging volume</td>
                                <td>30*30*14 CM</td>
                            </tr>
                            <tr className={cls.last}>
                                <td>Material</td>
                                <td>
                                    Aluminium alloy case
                                </td>
                            </tr>
                        </table>
                    </div>
                )}
                {/* {selectedTab === "docs" && (
                    <div className={cls.docs}>
                        <div className={cls.docCard}>
                            <File />
                            Certificate. jpg
                        </div>
                        <div className={cls.docCard}>
                            <File />
                            Certificate. jpg
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    )
}
