import React from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import { ReactComponent as Picture } from "@/shared/assets/logo.svg"
import { ReactComponent as BlackPicture } from "@/shared/assets/blackLogo.svg"
import { Link, useLocation } from "react-router-dom"
import AnchorLink from "react-anchor-link-smooth-scroll"
import cls from "./logo.module.scss"

export const Logo = ({ className }) => {
    const location = useLocation()
    const setAnchor = (child) => {
        return (
            <AnchorLink
                style={{
                    textDecoration: "none",
                    color: "inherit",
                }}
                offset={() => 200}
                href={"#home"}>
                {child}
            </AnchorLink>
        )
    }

    const setLink = (child) => {
        return (
            <Link
                style={{
                    textDecoration: "none",
                    color: "inherit",
                }}
                to={"/"}>
                {child}
            </Link>
        )
    }
    const wrapper = (child) => {
        return location.pathname !== "/"
            ? setLink(child)
            : setAnchor(child)
    }
    return wrapper(
        <div className={classNames(cls.Logo, [className])}>
            {location.pathname === "/" ? (
                <Picture />
            ) : (
                <BlackPicture />
            )}
        </div>
    )
}
