import React from "react"
import dashboard from "@/shared/assets/icons/dashboard.svg"
import gamingPad from "@/shared/assets/icons/gaming-pad.svg"
import software from "@/shared/assets/icons/software-developer.svg"
import hardware from "@/shared/assets/icons/chart-tree-map.svg"
import { ReactComponent as AxgridLogo } from "@/shared/assets/icons/AxgridLogo.svg"
import { ReactComponent as Substract } from "@/shared/assets/icons/Subtract.svg"
import { ReactComponent as DiagArrow } from "@/shared/assets/icons/diagArrow.svg"
import waveUp from "@/shared/assets/waveUp.webp"
import waveDown from "@/shared/assets/waveDown.webp"
import cls from "./services.module.scss"
import { Button } from "@/shared/ui/Button/Button"

export const Services = ({ id }) => {
    const data = [
        {
            name: "Software Development",
            icon: software,
        },
        {
            name: "SaaS products",
            icon: dashboard,
        },
        {
            name: "Game Development",
            icon: gamingPad,
        },
        {
            name: "Hardware Development",
            icon: hardware,
        },
    ]
    return (
        <div
            id={id}
            className={cls.services}>
            <img
                src={waveUp}
                className={cls.waveUp}
                alt='wave'
            />
            <h4>Services</h4>
            <div className={cls.container}>
                <ul className={cls.directions}>
                    {data.map((item) => {
                        return (
                            <li
                                className={
                                    cls.directionsItem
                                }
                                key={item}>
                                <img
                                    src={item.icon}
                                    alt={item.name}
                                />
                                <h2>{item.name}</h2>
                            </li>
                        )
                    })}
                </ul>
                <div className={cls.statisctics}>
                    <AxgridLogo
                        className={cls.AxgridLogo}
                    />
                    <p>
                        Axgrid is the name of our creative
                        team, responsible for crafting
                        outstanding designs and developing
                        all of our software products.
                    </p>

                    <div className={cls.statItem}>
                        <h2>21.3K+</h2>
                        <span>Users of our Products</span>
                    </div>
                    <div className={cls.statItem}>
                        <h2>$387K+</h2>
                        <span>
                            Helped to Earn our Clients
                        </span>
                    </div>
                </div>
            </div>
            <button
            onClick={() => window.open('https://axgrid.com/')}
                className={cls.moreAxgridButton}
                variant='black'>
                More about Axgrid
                <DiagArrow className={cls.DiagArrow} />
            </button>
            <img
                src={waveDown}
                className={cls.waveDown}
                alt='wave'
            />
            <Substract className={cls.subtract} />
        </div>
    )
}
