import React, { useEffect, useState } from "react"
import cls from "./licensecheck.module.scss"
import { LicenseStatus } from "@/shared/ui/LicenseStatus/LicenseStatus"
import { Button } from "@/shared/ui/Button/Button"
import { TextField } from "@/shared/ui/TextField/TextField"
import { ReactComponent as DiagArrow } from "@/shared/assets/icons/diagArrow.svg"
import licenseImage from "@/shared/assets/licenseImage.png"
import { license_key } from "./licenseKey"

export const LicenseCheck = ({
    purchaseDate = "23.07.2024",
    expireDate = "23.07.2024",
}) => {
    const [email, setEmail] = useState("")
    const [status, setStatus] = useState("valid")

    useEffect(() => {
        if (license_key % 3 === 0) {
            setStatus("valid")
        } else if (license_key % 3 === 1) {
            setStatus("expire")
        } else {
            setStatus("soon")
        }
    })

    return (
        <div className={cls.licenseCheck}>
            <div className={cls.header}>
                <h2>License verification</h2>
                <a>Buy new license</a>
            </div>
            <LicenseStatus
                className={cls.licenseStatus}
                status={status}
            />
            <div className={cls.licenseInfoContainer}>
                <div className={cls.licenseInfo}>
                    <div className={cls.licenseNumber}>
                        <p>License number:</p>
                        <span>{license_key}</span>
                    </div>
                    <div className={cls.licenseNumber}>
                        <p>Date of purchase:</p>
                        <span>{purchaseDate}</span>
                    </div>
                    <div className={cls.licenseNumber}>
                        <p>Expiry date:</p>
                        <span>{expireDate}</span>
                    </div>
                </div>
                <Button
                    className={cls.renewButton}
                    variant='secondary'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'>
                        <path
                            d='M15.5 18.5H20.5M18 16V21M13 3H9.8C8.11984 3 7.27976 3 6.63803 3.32698C6.07354 3.6146 5.6146 4.07354 5.32698 4.63803C5 5.27976 5 6.11984 5 7.8V16.2C5 17.8802 5 18.7202 5.32698 19.362C5.6146 19.9265 6.07354 20.3854 6.63803 20.673C7.27976 21 8.11984 21 9.8 21H12M13 3L19 9M13 3V5.8C13 6.9201 13 7.48016 13.218 7.90798C13.4097 8.28431 13.7157 8.59027 14.092 8.78201C14.5198 9 15.0799 9 16.2 9H19M19 9V12M9 17H12M9 13H15M9 9H10'
                            stroke='white'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                    </svg>
                    Renew the license
                </Button>
            </div>

            <div className={cls.licenseCheckForm}>
                <div className={cls.headContainer}>
                    <h3>
                        Do you want to check several
                        licenses at once?
                    </h3>
                </div>
                <TextField
                    value={email}
                    onChange={(e) =>
                        setEmail(e.target.value)
                    }
                    className={cls.licenseField}
                    withoutWarning
                    simplePlaceholder
                    isValid={true}
                    placeholder={"Email"}
                />
                <Button
                    variant='primary'
                    className={cls.checkButton}>
                    <DiagArrow className={cls.DiagArrow} />
                </Button>
            </div>
        </div>
    )
}
