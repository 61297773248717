import React, { useEffect, useState } from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import cls from "./navigation.module.scss"
import { useTranslation } from "react-i18next"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Link, useLocation } from "react-router-dom"
import useResize from "@/shared/lib/hooks/useResize"

export const Navigation = ({ className }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const windowWidth = useResize()
    const navItems = [
        {
            name: t("navigation.home"),
            link: "home",
            offset: 200,
        },
        {
            name: t("navigation.services"),
            link: "services",
            offset: 0,
        },
        {
            name: t("navigation.products"),
            link: "products",
            offset: 0,
        },
        {
            name: t("navigation.about"),
            link: "about",
            offset: 0,
        },
        {
            name: t("navigation.contacts"),
            link: "contactUs",
            offset: 0,
        },
    ]

    const setAnchor = (child, href, offset) => {
        return (
            <AnchorLink
                style={{
                    textDecoration: "none",
                    color: "inherit",
                }}
                offset={() => offset}
                href={href}>
                {child}
            </AnchorLink>
        )
    }

    const setLink = (child) => {
        return (
            <Link
                style={{
                    textDecoration: "none",
                    color: "inherit",
                }}
                to={"/"}>
                {child}
            </Link>
        )
    }
    const wrapper = (child, href, offset) => {
        return location.pathname !== "/"
            ? setLink(child)
            : setAnchor(child, href, offset)
    }
    return (
        <ul
            className={classNames(cls.Navigation, [
                className,
            ])}>
            {navItems.map((navItem) => {
                return wrapper(
                    <li
                        className={classNames(
                            cls.item,
                            [],
                            {}
                        )}
                        key={navItem.name}>
                        <p>{navItem.name}</p>
                    </li>,
                    "#" + navItem.link,
                    navItem.offset
                )
            })}
        </ul>
    )
}
