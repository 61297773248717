import { Outlet } from "react-router-dom"
import "./App.scss"
import { Header } from "@/widgets/Header/Header"
import { Footer } from "@/widgets/footer/Footer"

function App() {
    return (
        <div className='App'>
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}

export default App
