import React, { useEffect, useState } from "react"

import cls from "./header.module.scss"
import { Logo } from "@/shared/ui/Logo/Logo"
import { Navigation } from "@/features/Navigation/Navigation"
import { Button } from "@/shared/ui/Button/Button"
import { useTranslation } from "react-i18next"
import { ReactComponent as Menu } from "@/shared/assets/icons/menu.svg"
import { ReactComponent as XMark } from "@/shared/assets/icons/xMark.svg"
import { ReactComponent as MiniLogo } from "@/shared/assets/icons/miniLogo.svg"
import useResize from "@/shared/lib/hooks/useResize"
import { classNames } from "@/shared/lib/utils/classNames"
import { useScrollDirection } from "@/shared/lib/hooks/useSrollDirection"
import { useLocation } from "react-router-dom"

export const Header = () => {
    const { t } = useTranslation()
    const [menuOpen, setOpen] = useState(false)
    const [isScrolling, setScrolling] = useState(true)
    const [trans, setTrans] = useState(false)
    const windowWidth = useResize()
    const scrollDirection = useScrollDirection()
    const location = useLocation()
    useEffect(() => {
        let scrolling
        const handleScrollStop = (event) => {
            setOpen(false)
            window.clearTimeout(scrolling)
            setScrolling(false)
            scrolling = setTimeout(function () {
                setScrolling(true)
            }, 10)
        }

        window.addEventListener("scroll", handleScrollStop)
    }, [isScrolling, setScrolling, setOpen])
    useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setTrans(true)
            } else {
                setTrans(false)
            }
        })
    })
    const desktopHeader = () => {
        return (
            <div
                className={classNames(
                    cls.desktopContainer,
                    [],
                    {
                        [cls.black]:
                            trans &&
                            location.pathname === "/",
                    }
                )}>
                <Logo />
                <Navigation />
                <div className={cls.buttonContainer}>
                    <a href='https://softnersolutions.myshopify.com/'>
                        <Button
                            className={cls.LogInButton}
                            variant='primary'
                            withArrow>
                            {t("buttons.GetInnovation")}
                        </Button>
                    </a>
                </div>
            </div>
        )
    }
    const mobileHeader = () => {
        return (
            <>
                <div
                    style={{
                        height: document.documentElement
                            .clientHeight,
                    }}
                    className={classNames(cls.menu, [], {
                        [cls.close]: !menuOpen,
                    })}>
                    <Navigation />
                    <div className={cls.buttonContainer}>
                        <a href='https://softnersolutions.myshopify.com/'>
                            <Button
                                className={cls.LogInButton}
                                variant='primary'
                                withArrow>
                                {t("buttons.GetInnovation")}
                            </Button>
                        </a>
                    </div>
                </div>
                <div
                    className={classNames(
                        cls.mobileContainer,
                        [],
                        {
                            [cls.black]: trans,
                        }
                    )}>
                    <MiniLogo
                        className={classNames(
                            cls.MiniLogo,
                            [],
                            {
                                [cls.black]:
                                    location.pathname !==
                                    "/",
                                [cls.open]: menuOpen,
                            }
                        )}
                        onClick={() =>
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            })
                        }
                    />
                    {!menuOpen ? (
                        <Menu
                            onClick={() =>
                                setOpen((prev) => !prev)
                            }
                            className={classNames(
                                cls.menuIcon,
                                [],
                                {
                                    [cls.black]:
                                        location.pathname !==
                                        "/",
                                    [cls.open]: menuOpen,
                                }
                            )}
                        />
                    ) : (
                        <XMark
                            onClick={() =>
                                setOpen((prev) => !prev)
                            }
                            className={classNames(
                                cls.menuIcon,
                                [],
                                {
                                    [cls.black]:
                                        location.pathname !==
                                        "/",
                                    [cls.open]: menuOpen,
                                }
                            )}
                        />
                    )}
                </div>
            </>
        )
    }
    return (
        <header
            className={classNames(cls.Header, [], {
                [cls.close]: scrollDirection !== "up",
                [cls.black]:
                    trans && location.pathname === "/",
                [cls.white]: location.pathname !== "/",
            })}>
            {windowWidth <= 1100
                ? mobileHeader()
                : desktopHeader()}
        </header>
    )
}
