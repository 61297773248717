import React, { useEffect, useState } from "react"
import macBook from "@/shared/assets/macBook.webp"
import miniPC from "@/shared/assets/miniPC.webp"
import gamepads from "@/shared/assets/gamepads.webp"
import easyLaunch from "@/shared/assets/easyLaunch.webp"
import circles from "@/shared/assets/circles.svg"
import bgLine from "@/shared/assets/bgLine.svg"
import licenseImage from "@/shared/assets/licenseImage.png"
import { ReactComponent as DownloadIcon } from "@/shared/assets/icons/downloadIcon.svg"
import { ReactComponent as DiagArrow } from "@/shared/assets/icons/diagArrow.svg"
import comingSoon from "@/shared/assets/comingSoon.svg"
import cls from "./products.module.scss"
import { Button } from "@/shared/ui/Button/Button"
import { classNames } from "@/shared/lib/utils/classNames"
import Modal from "@/shared/ui/Modal/Modal"
import { Configuration } from "@/entities/Configuration/Configuration"
import { TextField } from "@/shared/ui/TextField/TextField"
import { Link } from "react-router-dom"
import { setLicenseKey } from "@/page/LicenseCheck/licenseKey"

export const ProductsBlock = ({ id }) => {
    const [modal, setModal] = useState(false)
    const [licenseKey, setLicenseKeyState] = useState()
    useEffect(() => {
        const slider = document.getElementById("slider")

        let isDown = false
        let startX
        let scrollLeft

        slider.addEventListener("mousedown", (e) => {
            isDown = true
            slider.classList.add("active")
            startX = e.pageX - slider.offsetLeft
            scrollLeft = slider.scrollLeft
        })
        slider.addEventListener("mouseleave", () => {
            isDown = false
            slider.classList.remove("active")
        })
        slider.addEventListener("mouseup", () => {
            isDown = false
            slider.classList.remove("active")
        })
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return
            e.preventDefault()
            const x = e.pageX - slider.offsetLeft
            const walk = (x - startX) * 3 //scroll-fast
            slider.scrollLeft = scrollLeft - walk
        })
    })
    return (
        <>
            <div
                id={id}
                className={cls.ProductsBlock}>
                <div className={cls.headerContainer}>
                    <h4>Products</h4>
                    <a
                        className={cls.visitButton}
                        href='https://softnersolutions.myshopify.com/'>
                        <Button
                            className={cls.visitButton}
                            variant='primary'>
                            <p>Visit our store</p>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='25'
                                viewBox='0 0 24 25'
                                fill='none'>
                                <path
                                    d='M6.29977 5.5H21L19 12.5H7.37671M20 16.5H8L6 3.5H3M9 20.5C9 21.0523 8.55228 21.5 8 21.5C7.44772 21.5 7 21.0523 7 20.5C7 19.9477 7.44772 19.5 8 19.5C8.55228 19.5 9 19.9477 9 20.5ZM20 20.5C20 21.0523 19.5523 21.5 19 21.5C18.4477 21.5 18 21.0523 18 20.5C18 19.9477 18.4477 19.5 19 19.5C19.5523 19.5 20 19.9477 20 20.5Z'
                                    stroke='#131A29'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                />
                            </svg>
                        </Button>
                    </a>
                </div>
                <div
                    id='slider'
                    className={cls.container}>
                    <div
                        className={classNames(cls.miniPC, [
                            cls.card,
                        ])}>
                        <img
                            className={cls.image}
                            src={miniPC}
                            alt='miniPC'
                        />
                        <div className={cls.text}>
                            <h1>Softner mini PC</h1>
                            <p>
                                This product delivers
                                exceptional speed and
                                efficiency, perfectly
                                balancing power with an
                                efficient, space-saving
                                design, all at an
                                outstanding
                                price-performance ratio.
                            </p>
                        </div>

                        <div className={cls.buttons}>
                            <Button
                                onClick={() =>
                                    window.open(
                                        window.location
                                            .origin +
                                            "/docs/miniPCDoc.pdf"
                                    )
                                }
                                className={
                                    cls.downloadButton
                                }
                                variant='white'>
                                Download presentation
                                <DownloadIcon />
                            </Button>
                            <Button
                                className={cls.configButton}
                                onClick={() =>
                                    setModal(true)
                                }
                                variant='outlined'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='25'
                                    viewBox='0 0 24 25'
                                    fill='none'>
                                    <path
                                        d='M20.5313 9.29905L19.0347 6.70095L13.5 9.90191V3.5H10.5V9.90191L4.96536 6.70095L3.46826 9.29905L9.00434 12.5L3.46868 15.701L4.96531 18.299L10.5 15.0981V21.5H13.5V15.0981L19.0347 18.299L20.5318 15.701L14.9957 12.5L20.5313 9.29905Z'
                                        fill='white'
                                    />
                                </svg>
                                View configuration
                            </Button>
                        </div>
                    </div>
                    <div
                        className={classNames(
                            cls.customGamepads,
                            [cls.card]
                        )}>
                        <img
                            className={cls.image}
                            src={gamepads}
                            alt='miniPC'
                        />
                        <div className={cls.text}>
                            <h1>Custom gamepads</h1>
                            <p>
                                Our controllers offer
                                precise responsiveness and
                                durability, delivering a
                                seamless gaming experience
                                with an ergonomic,
                                user-friendly design
                            </p>
                        </div>
                        <img
                            src={circles}
                            className={cls.circles}
                            alt='circles'
                        />
                    </div>
                    <div
                        className={classNames(
                            cls.operatingSystem,
                            [cls.card]
                        )}>
                        <img
                            className={cls.image}
                            src={macBook}
                            alt='miniPC'
                        />
                        <div className={cls.text}>
                            <h1>Operating system</h1>
                            <p>
                                Our Linux-based operating
                                system offers effortless
                                configuration in just two
                                clicks. Allows seamless
                                access to the game lobby,
                                comprehensive admin
                                services, and all essential
                                features tailored to your
                                needs.
                            </p>
                            <img
                                className={cls.easyLaunch}
                                src={easyLaunch}
                                alt='easyl'
                            />
                        </div>
                    </div>
                </div>
                <div className={cls.licenseCheck}>
                    <img
                        src={licenseImage}
                        className={cls.licenseImage}
                        alt=''
                    />
                    <div className={cls.headContainer}>
                        <h3>License verification</h3>
                        <a>Buy new license</a>
                    </div>
                    <TextField
                        value={licenseKey}
                        onChange={(e) => {
                            setLicenseKeyState(
                                e.target.value.replace(
                                    /\D/g,
                                    ""
                                )
                            )
                            setLicenseKey(
                                e.target.value.replace(
                                    /\D/g,
                                    ""
                                )
                            )
                        }}
                        maxlength={5}
                        className={cls.licenseField}
                        withoutWarning
                        simplePlaceholder
                        isValid={true}
                        placeholder={
                            "Enter the license number"
                        }
                    />
                    <Link to={"/license_check"}>
                        <Button
                            variant='primary'
                            className={cls.checkButton}>
                            <DiagArrow
                                className={cls.DiagArrow}
                            />
                        </Button>
                    </Link>
                </div>
                <svg
                    className={cls.SomeIcon}
                    xmlns='http://www.w3.org/2000/svg'
                    width='118'
                    height='153'
                    viewBox='0 0 118 153'
                    fill='none'>
                    <path
                        d='M-11.9021 56.2086H-11.649V55.9555V31.1824H9.2958V55.9555V56.2086H9.5489L34.322 56.2086V77.1534H9.5489H9.2958V77.4065V102.18H-11.649V77.4065V77.1534H-11.9021H-36.6752L-36.6752 56.2086L-11.9021 56.2086Z'
                        fill='white'
                    />
                    <path
                        d='M-11.9021 56.2086H-11.649V55.9555V31.1824H9.2958V55.9555V56.2086H9.5489L34.322 56.2086V77.1534H9.5489H9.2958V77.4065V102.18H-11.649V77.4065V77.1534H-11.9021H-36.6752L-36.6752 56.2086L-11.9021 56.2086Z'
                        stroke='white'
                        stroke-width='0.506193'
                    />
                    <path
                        d='M-11.9021 56.2086H-11.649V55.9555V31.1824H9.2958V55.9555V56.2086H9.5489L34.322 56.2086V77.1534H9.5489H9.2958V77.4065V102.18H-11.649V77.4065V77.1534H-11.9021H-36.6752L-36.6752 56.2086L-11.9021 56.2086Z'
                        stroke='white'
                        stroke-width='0.506193'
                    />
                    <path
                        d='M79.4762 3.06601V0.421143H64.2529V18.1817H46.4924V33.405H49.1372M79.4762 3.06601H66.8977V20.8265H49.1372V33.405M79.4762 3.06601H82.121V18.1817M79.4762 3.06601V4.01939M49.1372 33.405H50.0906M49.1372 33.405V36.0499H64.2529M50.0906 33.405L50.0906 21.7799H67.8511V4.01939H79.4762M50.0906 33.405H64.2529V35.0965M50.0906 33.405V35.0965H64.2529M64.2529 36.0499V51.1655H66.8977M64.2529 36.0499V35.0965M64.2529 36.0499H66.8977V51.1655M66.8977 51.1655V53.8104H82.121V36.0499H99.8816V20.8265H97.2367M66.8977 51.1655H67.8511M97.2367 20.8265V18.1817H82.121M97.2367 20.8265V21.7799M97.2367 20.8265H82.121V18.1817M82.121 18.1817H81.1677M79.4762 4.01939H81.1677V18.1817M79.4762 4.01939V18.1817H81.1677M64.2529 35.0965H67.8511V51.1655M67.8511 51.1655V52.857H81.1677V35.0965H98.9282V21.7799H97.2367M67.8511 51.1655H79.4762V33.405H97.2367V21.7799M97.2367 21.7799H81.1677V18.1817'
                        fill='white'
                    />
                    <path
                        d='M79.4762 3.06601V0.421143H64.2529V18.1817H46.4924V33.405H49.1372M79.4762 3.06601H66.8977V20.8265H49.1372V33.405M79.4762 3.06601H82.121V18.1817M79.4762 3.06601V4.01939M49.1372 33.405H50.0906M49.1372 33.405V36.0499H64.2529M50.0906 33.405L50.0906 21.7799H67.8511V4.01939H79.4762M50.0906 33.405H64.2529V35.0965M50.0906 33.405V35.0965H64.2529M64.2529 36.0499V51.1655H66.8977M64.2529 36.0499V35.0965M64.2529 36.0499H66.8977V51.1655M66.8977 51.1655V53.8104H82.121V36.0499H99.8816V20.8265H97.2367M66.8977 51.1655H67.8511M97.2367 20.8265V18.1817H82.121M97.2367 20.8265V21.7799M97.2367 20.8265H82.121V18.1817M82.121 18.1817H81.1677M79.4762 4.01939H81.1677V18.1817M79.4762 4.01939V18.1817H81.1677M64.2529 35.0965H67.8511V51.1655M67.8511 51.1655V52.857H81.1677V35.0965H98.9282V21.7799H97.2367M67.8511 51.1655H79.4762V33.405H97.2367V21.7799M97.2367 21.7799H81.1677V18.1817'
                        stroke='white'
                    />
                    <path
                        d='M79.4762 3.06601V0.421143H64.2529V18.1817H46.4924V33.405H49.1372M79.4762 3.06601H66.8977V20.8265H49.1372V33.405M79.4762 3.06601H82.121V18.1817M79.4762 3.06601V4.01939M49.1372 33.405H50.0906M49.1372 33.405V36.0499H64.2529M50.0906 33.405L50.0906 21.7799H67.8511V4.01939H79.4762M50.0906 33.405H64.2529V35.0965M50.0906 33.405V35.0965H64.2529M64.2529 36.0499V51.1655H66.8977M64.2529 36.0499V35.0965M64.2529 36.0499H66.8977V51.1655M66.8977 51.1655V53.8104H82.121V36.0499H99.8816V20.8265H97.2367M66.8977 51.1655H67.8511M97.2367 20.8265V18.1817H82.121M97.2367 20.8265V21.7799M97.2367 20.8265H82.121V18.1817M82.121 18.1817H81.1677M79.4762 4.01939H81.1677V18.1817M79.4762 4.01939V18.1817H81.1677M64.2529 35.0965H67.8511V51.1655M67.8511 51.1655V52.857H81.1677V35.0965H98.9282V21.7799H97.2367M67.8511 51.1655H79.4762V33.405H97.2367V21.7799M97.2367 21.7799H81.1677V18.1817'
                        stroke='white'
                    />
                    <path
                        d='M85.8515 105.173L102.832 88.1924L116.568 101.929L99.5879 118.909L98.8721 119.625L99.5879 120.341L116.568 137.321L102.832 151.058L85.8515 134.077L85.1356 133.361L84.4198 134.077L67.4394 151.058L53.703 137.321L70.6833 120.341L71.3992 119.625L70.6833 118.909L53.703 101.929L67.4394 88.1924L84.4198 105.173L85.1356 105.889L85.8515 105.173Z'
                        stroke='white'
                        stroke-width='2.02477'
                    />
                    <path
                        d='M85.8515 105.173L102.832 88.1924L116.568 101.929L99.5879 118.909L98.8721 119.625L99.5879 120.341L116.568 137.321L102.832 151.058L85.8515 134.077L85.1356 133.361L84.4198 134.077L67.4394 151.058L53.703 137.321L70.6833 120.341L71.3992 119.625L70.6833 118.909L53.703 101.929L67.4394 88.1924L84.4198 105.173L85.1356 105.889L85.8515 105.173Z'
                        stroke='white'
                        stroke-width='2.02477'
                    />
                </svg>
                <img
                    src={bgLine}
                    className={cls.bgLine1}
                    alt=''
                />
                <img
                    src={bgLine}
                    className={cls.bgLine2}
                    alt=''
                />
            </div>
            <Modal
                isOpen={modal}
                setOpen={setModal}>
                <Configuration
                    onClick={() => setModal(false)}
                />
            </Modal>
        </>
    )
}
