import React from "react"
import cls from "./licensestatus.module.scss"
import { ReactComponent as Check } from "@/shared/assets/icons/Check.svg"
import { ReactComponent as XMark } from "@/shared/assets/icons/xMark.svg"
import { ReactComponent as IconTime } from "@/shared/assets/icons/iconTime.svg"
import { classNames } from "@/shared/lib/utils/classNames"

export const LicenseStatus = ({
    className,
    status = "expire",
}) => {
    return (
        <div
            className={classNames(
                cls.LicenseStatus,
                [className],
                {
                    [cls.expire]:
                        status === "expire",
                    [cls.valid]: status === "valid",
                    [cls.soon]: status === "soon",
                }
            )}>
            {status === "valid" && <Check />}
            {status === "expire" && <XMark />}
            {status === "soon" && <IconTime />}
            {status === "valid" && (
                <p>Your license is valid</p>
            )}
            {status === "expire" && (
                <p>Your license is expired</p>
            )}
            {status === "soon" && (
                <p>Your license is expires soon</p>
            )}
        </div>
    )
}
