import React from "react"
import megaplaza from "@/shared/assets/megamallplaza.webp"
import bar12 from "@/shared/assets/bar12.webp"
import cls from "./feedback.module.scss"
import { FeedbackCard } from "@/entities/FeedbackCard/FeedbackCard"
import Marquee from "react-fast-marquee"

export const Feedback = () => {
    return (
        <div className={cls.Feedback}>
            <h4>Feedback</h4>
            <Marquee className={cls.marquee}>
                <div className={cls.container}>
                    <FeedbackCard
                        src={megaplaza}
                        title={"Mega Mall Plaza, Tokyo"}
                        description={
                            "Mega Mall Plaza’s gaming installation delivers a unique, interactive experience that captivates visitors and keeps them entertained. This engaging attraction not only enhances the shopping environment but also increases dwell time, contributing to higher sales and boosting the mall’s profitability."
                        }
                    />
                    <FeedbackCard
                        src={bar12}
                        title={"Bar 12, Dortmund"}
                        description={
                            "Our gaming set installed in the bar offers guests thrilling experiences, creating unforgettable emotions that keep them coming back. Not only does it enhance customer satisfaction, but it also boosts business profits, making it a valuable investment for any venue."
                        }
                    />
                    <FeedbackCard
                        src={megaplaza}
                        title={"Mega Mall Plaza, Tokyo"}
                        description={
                            "Mega Mall Plaza’s gaming installation delivers a unique, interactive experience that captivates visitors and keeps them entertained. This engaging attraction not only enhances the shopping environment but also increases dwell time, contributing to higher sales and boosting the mall’s profitability."
                        }
                    />
                    <FeedbackCard
                        src={bar12}
                        title={"Bar 12, Dortmund"}
                        description={
                            "Our gaming set installed in the bar offers guests thrilling experiences, creating unforgettable emotions that keep them coming back. Not only does it enhance customer satisfaction, but it also boosts business profits, making it a valuable investment for any venue."
                        }
                    />
                </div>
            </Marquee>
        </div>
    )
}
